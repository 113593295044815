.header{
    display: flex;
    justify-content: space-between;
}

.logo{
    width: 10rem;
    height:3rem;
    border-radius: 7px;
}
/* .logo{
    width: 5rem;
    height:5rem;
    border-radius: 15px;
    
} */

.header-menu{
    list-style:none;
    display: flex;
    gap:2rem;
    color:white;
    text-transform: uppercase;
    
}

.header-menu li:hover{
    color: var(--orange);
    cursor: pointer;
      /* font-family: source sans pro monospace; */
}

@media screen and (max-width: 768px){
    .header>:nth-child(2){
        position: fixed;
        right: 2rem;
        z-index: 99;
    }

    .header-menu{
        flex-direction: column;
        background-color: var(--appColor);
        padding: 2rem;
        border-radius: 5%;
    }
}